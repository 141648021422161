<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="700px">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t(`home.dialogs.place.${place ? "edit" : "new"}`) }}
        </v-card-title>
        <v-card-title> {{ $t("home.dialogs.place.name") }}</v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="nameRules"
                  :label="$t('common.japanese')"
                  v-model="placeJapName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="nameRules" :label="$t('common.english')" v-model="placeName">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>{{ $t("common.images.images") }}</v-card-title>
          <v-card-subtitle class="">
            <small>{{ $t("common.images.suggestedSize") }}</small>
          </v-card-subtitle>
          <v-card-text>
            <v-slide-group show-arrows>
              <v-slide-item v-for="(image, index) in images" :key="image">
                <v-img
                  max-height="100"
                  max-width="166"
                  class="ma-2 text-right pa-2"
                  style="border-radius: 8px"
                  :src="image"
                >
                  <v-btn @click="removeImage(index)" icon class="white" x-small color="primary">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-img>
              </v-slide-item>
              <v-slide-item v-for="(image, index) in newImages" :key="index">
                <v-img
                  class="ma-2 text-right pa-2"
                  style="width: 100px; height: 100px; border-radius: 8px"
                  :src="image"
                >
                  <v-btn @click="removeNewImage(index)" icon class="white" x-small color="primary">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-img>
              </v-slide-item>
              <v-slide-item>
                <DragAndDrop title="Add image" v-on:update:file="onFileAdded" />
              </v-slide-item>
            </v-slide-group>
          </v-card-text>
          <v-card-title>{{ $t("common.location.location") }}</v-card-title>
          <v-card-text>
            <div class="d-flex justify-center">
              <MglMap
                v-if="!manual || hasShownMap"
                v-show="!manual"
                style="width: 80%; height: 350px"
                :accessToken="accessToken"
                :mapStyle="mapStyle"
                @load="mapLoaded"
              >
                <MglMarker
                  v-if="marker"
                  anchor="bottom"
                  color="#9652ff"
                  :coordinates="marker.coordinates"
                >
                </MglMarker>
              </MglMap>
            </div>
            <v-row v-show="manual" v-if="marker">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="marker.coordinates[1]"
                  :label="$t('common.location.latitude')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="marker.coordinates[0]"
                  :label="$t('common.location.longitude')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn outlined color="primary" @click="manual = !manual">
              {{ $t(`common.location.${manual ? "manual" : "coordinates"}`) }}
            </v-btn>
            <p class="text-end mt-2 mb-0 error--text" v-if="errorMessage">{{ errorMessage }}</p>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-dialog v-if="place != null" v-model="deleteDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" text v-bind="attrs" v-on="on"> Delete </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 error white--text">
                {{ $t("home.dialogs.place.deletePlace", { placeJapName }) }}
              </v-card-title>

              <v-card-text class="text-h6">
                {{ $t("home.dialogs.place.deletePlaceDesc") }}
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteDialog = false">
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-btn color="error" @click="deletePlace"> {{ $t("buttons.delete") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> {{ $t("buttons.cancel") }} </v-btn>
          <v-btn color="primary" :loading="loading" @click="updatePlace">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "v-mapbox";
import database from "@/firebase/database";
import DragAndDrop from "@/components/DragAndDrop";
import aws from "../aws/aws";

const initialState = (self) => ({
  dialog: false,
  deleteDialog: false,
  placeName: null,
  placeJapName: null,
  images: [],
  loading: false,
  marker: { coordinates: [null, null] },
  manual: true,
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS,
  mapStyle: process.env.VUE_APP_MAPSTYLE,
  newImages: [],
  newImageFiles: [],
  removeImages: [],
  hasShownMap: false,
  errorMessage: null,
  nameRules: [
    (v) => !!v || self.$t("common.errors.noName"),
    (v) => (v && v.length >= 2) || self.$t("common.errors.nameLength", { chars: "2" }),
  ],
});

export default {
  props: ["country_id", "city_id", "place_id"],
  components: {
    MglMap,
    MglMarker,
    DragAndDrop,
  },
  data(self) {
    return initialState(self);
  },
  methods: {
    deletePlace() {
      database.deletePlace(this.$props.country_id, this.$props.city_id, this.$props.place_id);
      this.close();
      this.$router.go(-1);
    },
    removeImage(index) {
      this.removeImages.push(this.images.splice(index, 1)[0]);
    },
    close() {
      Object.assign(this.$data, initialState(this));
      if (this.place) {
        this.setPlace(this.place);
      }
    },
    async updatePlace() {
      if (!this.$refs.form.validate()) return;
      if (
        this.marker.coordinates[0] == null ||
        this.marker.coordinates[1] == null ||
        this.marker.coordinates[0] === "" ||
        this.marker.coordinates[1] === "" ||
        Number.isNaN(parseFloat(this.marker.coordinates[0])) ||
        Number.isNaN(parseFloat(this.marker.coordinates[1]))
      ) {
        this.errorMessage = this.$t("home.dialogs.place.errorNoLocation");
        return;
      }

      if (this.images.length === 0) {
        this.errorMessage = this.$t("home.dialogs.place.errorNoImage");
        return;
      }
      this.errorMessage = null;
      const images = [...this.images];
      this.loading = true;

      await Promise.all([
        ...this.newImageFiles.map(async (image) => {
          const response = await aws.addImage(image);
          images.push(`https://d3moby4pk1k6wv.cloudfront.net/${response}`);
        }),
        // ...this.removeImages.map((imageUrl) => {
        //   const imageName = imageUrl.split(".net/")[1];
        //   if (imageName) {
        //     return aws.deleteImage(null, imageName);
        //   }
        //   return true;
        // }),
      ]);

      await database.updatePlace(
        this.$props.country_id,
        this.$props.city_id,
        this.$props.place_id,
        this.placeName,
        this.placeJapName,
        images,
        {
          lat: parseFloat(this.marker.coordinates[1]),
          long: parseFloat(this.marker.coordinates[0]),
        },
      );

      this.loading = false;
      this.close();
    },
    mapLoaded(payload) {
      this.hasShownMap = true;
      if (!this.marker.coordinates[0] && this.city) {
        this.marker = {
          coordinates: [
            this.city.coordinates.long.toString(),
            this.city.coordinates.lat.toString(),
          ],
        };
      }

      if (this.marker.coordinates[0]) {
        payload.map.jumpTo({
          zoom: 10,
          center: this.marker.coordinates,
        });
      }
      payload.map.on("click", (e) => {
        const { lngLat } = e;

        this.marker = { coordinates: [lngLat.lng, lngLat.lat] };
      });
    },
    removeNewImage(index) {
      this.newImageFiles.splice(index, 1);
      this.newImages.splice(index, 1);
    },
    onFileAdded(file) {
      if (!file) return;
      this.newImageFiles.push(file);
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.newImages.push(reader.result);
        },
        false,
      );
      reader.readAsDataURL(file);
    },
    geocoded(result) {
      this.marker = {
        coordinates: result.result.center,
      };
    },
    setPlace(place) {
      this.placeName = place.name;
      this.placeJapName = place.name_ja;
      this.images = [...place.images];
      this.marker = { coordinates: [place.coordinates.long, place.coordinates.lat] };
    },
  },
  computed: {
    mapboxgl() {
      return Mapbox;
    },
    place() {
      if (!this.city || !this.$props.place_id) return null;
      return this.city.places[this.$props.place_id];
    },
    city() {
      if (!this.country || !this.$props.city_id) return null;
      return this.country.cities[this.$props.city_id];
    },
    country() {
      if (!this.guides || !this.$props.country_id) return null;
      return this.guides[this.$props.country_id];
    },
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
  created() {
    if (this.place) {
      this.setPlace(this.place);
    }
  },
  watch: {
    place(newVal, oldVal) {
      if (
        newVal &&
        (!oldVal ||
          newVal.name !== oldVal.name ||
          newVal.name_ja !== oldVal.name_ja ||
          newVal.coordinates !== oldVal.coordinates)
      ) {
        this.setPlace(newVal);
      }
    },
  },
};
</script>

<style >
.mgl-map-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.mgl-map-wrapper .mapboxgl-map {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
