<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent scrollable width="700px">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t(`dialogs.guide.${guide ? "edit" : "new"}`) }}
        </v-card-title>
        <div class="overflow-y-auto" style="max-height: 500px">
          <v-card-title>{{ $t(`dialogs.guide.${guide ? "edit" : "new"}`) }}</v-card-title>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :rules="nameRules"
                    :label="$t('common.japanese')"
                    v-model="guideJapName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :rules="nameRules"
                    :label="$t('common.english')"
                    v-model="guideName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>{{ $t("common.images.images") }}</v-card-title>
            <v-card-subtitle class="">
              <small>{{ $t("common.images.suggestedSize") }}</small>
            </v-card-subtitle>
            <v-card-text>
              <v-slide-group show-arrows>
                <v-slide-item v-for="(image, index) in images" :key="image">
                  <v-img
                    class="ma-2 text-right pa-2"
                    style="width: 100px; height: 100px; border-radius: 8px"
                    :src="image"
                  >
                    <v-btn @click="removeImage(index)" icon class="white" x-small color="primary">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-img>
                </v-slide-item>
                <v-slide-item v-for="(image, index) in newImages" :key="index">
                  <v-img
                    class="ma-2 text-right pa-2"
                    style="width: 100px; height: 100px; border-radius: 8px"
                    :src="image"
                  >
                    <v-btn
                      @click="removeNewImage(index)"
                      icon
                      class="white"
                      x-small
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-img>
                </v-slide-item>
                <v-slide-item>
                  <DragAndDrop v-on:update:file="onFileAdded" />
                </v-slide-item>
              </v-slide-group>
            </v-card-text>
            <v-card-title>{{ $t("dialogs.guide.audioFile") }}</v-card-title>
            <v-card-subtitle>
              <small>{{ $t("dialogs.guide.audioFileDesc") }}</small>
            </v-card-subtitle>
            <v-card-text>
              <div class="d-flex align-center">
                <DragAndDrop
                  v-show="!fileStats"
                  :audio="true"
                  :height="120"
                  v-on:update:file="onAudioFileAdded"
                />
                <v-card class="ma-2 text-center" v-if="fileStats" min-height="120">
                  <v-card-text class="d-flex flex-row align-center">
                    <div class="d-flex flex-column align-start">
                      <div class="d-flex align-center">
                        <v-icon v-if="audioFile" color="success">mdi-check-circle</v-icon>
                        <v-icon v-else>mdi-cloud-outline</v-icon>
                        <h5 class="ml-3">{{ fileStats.name }}</h5>
                      </div>
                      <div class="mt-2">
                        <v-icon>mdi-timer-outline</v-icon>
                        <span class="ml-3">{{ fileStats.duration | formatDuration }}</span>
                      </div>
                      <div class="mt-2">
                        <v-icon>mdi-file</v-icon>
                        <span class="ml-3">{{ fileStats.size | formatBytes }}</span>
                      </div>
                    </div>
                    <v-btn class="ml-2" @click="deleteAudio" icon large color="error"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-card-text>
                </v-card>
              </div>
              <span v-if="fileError" class="error--text">{{ fileError }}</span>
            </v-card-text>
            <v-card-title>{{ $t("dialogs.guide.manuscript") }}</v-card-title>
            <v-card-text>
              <v-textarea
                name="input-7-1"
                :label="$t('dialogs.guide.manuscript')"
                v-model="guideManuscript"
                hide-details
              ></v-textarea>
            </v-card-text>
            <v-card-title>{{ $t("common.location.location") }}</v-card-title>
            <v-card-text>
              <div class="d-flex justify-center">
                <MglMap
                  v-if="!manual || hasShownMap"
                  v-show="!manual"
                  style="width: 80%; height: 350px"
                  :accessToken="accessToken"
                  :mapStyle="mapStyle"
                  @load="mapLoaded"
                >
                  <MglMarker
                    v-if="marker"
                    anchor="bottom"
                    color="#9652ff"
                    :coordinates="marker.coordinates"
                  >
                  </MglMarker>
                </MglMap>
              </div>
              <v-row v-show="manual" v-if="marker">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="marker.coordinates[1]"
                    :label="$t('common.location.latitude')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="marker.coordinates[0]"
                    :label="$t('common.location.longitude')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn outlined color="primary" @click="manual = !manual">
                {{ $t(`common.location.${manual ? "manual" : "coordinates"}`) }}
              </v-btn>
            </v-card-text>
            <v-card-subtitle>
              <span v-if="error" class="error--text">{{ error }}</span>
            </v-card-subtitle>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-dialog v-if="guide != null" v-model="deleteDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" v-bind="attrs" v-on="on"> Delete </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 error white--text">
                {{ $t("home.dialogs.place.deletePlace", { guideJapName }) }}
              </v-card-title>

              <v-card-text class="text-h6">
                {{ $t("dialogs.guide.deleteDesc") }}
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteDialog = false">
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-btn color="error" @click="deleteGuide"> {{ $t("buttons.delete") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> {{ $t("buttons.cancel") }} </v-btn>
          <v-btn color="primary" :loading="loading" @click="updateGuide">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "v-mapbox";
import audioGuides from "@/plugins/audioguides";
import database from "@/firebase/database";
import DragAndDrop from "@/components/DragAndDrop";
import aws from "../aws/aws";

const initialState = (self) => ({
  dialog: false,
  deleteDialog: false,
  guideName: null,
  guideJapName: null,
  guideManuscript: "",
  images: [],
  audioFile: null,
  fileStats: null,
  loading: false,
  marker: { coordinates: [null, null] },
  manual: true,
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS,
  mapStyle: process.env.VUE_APP_MAPSTYLE,
  newImages: [],
  newImageFiles: [],
  removeImages: [],
  removeAudio: null,
  hasShownMap: false,
  fileError: null,
  error: null,
  nameRules: [
    (v) => !!v || self.$t("common.errors.noName"),
    (v) => (v && v.length >= 2) || self.$t("common.errors.nameLength", { chars: "2" }),
  ],
});

export default {
  props: ["country_id", "city_id", "place_id", "guide_index"],
  components: {
    MglMap,
    MglMarker,
    DragAndDrop,
  },
  data(self) {
    return initialState(self);
  },
  methods: {
    removeImage(index) {
      this.removeImages.push(this.images.splice(index, 1)[0]);
    },
    deleteAudio() {
      this.audioFile = null;
      if (this.fileStats.url) {
        this.removeAudio = this.fileStats.name;
      }
      this.fileStats = null;
    },
    deleteGuide() {
      database.deleteGuide(
        this.$props.country_id,
        this.$props.city_id,
        this.$props.place_id,
        this.place.guides,
        this.$props.guide_index,
      );
      this.close();
    },
    async updateGuide() {
      if (!this.$refs.form.validate()) return;

      if (
        (this.marker.coordinates[0] != null || this.marker.coordinates[1] != null) &&
        (this.marker.coordinates[0] !== "" || this.marker.coordinates[1] !== "") &&
        (Number.isNaN(parseFloat(this.marker.coordinates[0])) ||
          Number.isNaN(parseFloat(this.marker.coordinates[1])))
      ) {
        this.error = this.$t("dialogs.guide.errorNoLocation");
        return;
      }

      if (!this.fileStats) {
        this.error = this.$t("dialogs.guide.errorNoAudio");
        return;
      }
      this.error = null;
      this.loading = true;
      const { images } = this;
      const audioGuide = { ...this.fileStats };
      const promises = [
        ...this.newImageFiles.map(async (image) => {
          const response = await aws.addImage(image);
          images.push(`https://d3moby4pk1k6wv.cloudfront.net/${response}`);
        }),
        // ...this.removeImages.map((imageUrl) => {
        //   const imageName = imageUrl.split(".net/")[1];
        //   if (imageName) {
        //     return aws.deleteImage(imageName);
        //   }
        //   return true;
        // }),
      ];

      // if (this.removeAudio) {
      //   promises.push(aws.deleteAudio(this.removeAudio));
      // }

      if (this.audioFile) {
        promises.push(
          aws.addGuide(this.audioFile).then((audio) => {
            audioGuide.url = `https://d3moby4pk1k6wv.cloudfront.net/${audio}`;
          }),
        );
      }
      await Promise.all(promises);

      let location;
      if (this.marker.coordinates[0]) {
        location = {
          lat: parseFloat(this.marker.coordinates[1]),
          long: parseFloat(this.marker.coordinates[0]),
        };
      }
      await database.updateGuide(
        this.$props.country_id,
        this.$props.city_id,
        this.$props.place_id,
        this.$props.guide_index,
        this.guideName,
        this.guideJapName,
        this.guideManuscript,
        audioGuide,
        images,
        location,
      );

      this.close();
    },
    close() {
      Object.assign(this.$data, initialState(this));
      this.$emit("closed");
    },
    mapLoaded(payload) {
      this.hasShownMap = true;

      if (this.marker.coordinates[0]) {
        payload.map.jumpTo({
          zoom: 10,
          center: this.marker.coordinates,
        });
      }
      payload.map.on("click", (e) => {
        const { lngLat } = e;

        this.marker = { coordinates: [lngLat.lng, lngLat.lat] };
      });
    },
    async onAudioFileAdded(file) {
      if (!file.name.includes(".mp3")) {
        this.fileError = this.$t("dialogs.guide.fileError");
        return;
      }
      this.fileError = null;
      this.audioFile = file;
      audioGuides.getAudioFileStats(this.audioFile).then((res) => {
        this.fileStats = { name: file.name, duration: res.duration, size: res.size };
      });
    },
    removeNewImage(index) {
      this.newImageFiles.splice(index, 1);
      this.newImages.splice(index, 1);
    },
    onFileAdded(file) {
      if (!file) return;
      this.newImageFiles.push(file);
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.newImages.push(reader.result);
        },
        false,
      );
      reader.readAsDataURL(file);
    },
    geocoded(result) {
      this.marker = {
        coordinates: result.result.center,
      };
    },
    setGuide(guide) {
      this.guideName = guide.title;
      this.guideJapName = guide.title_ja;
      this.guideManuscript = guide.manuscript_ja || "";
      this.images = [...(guide.images || [])];

      this.fileStats = {
        name: guide.url.split("/").pop(),
        duration: guide.duration,
        size: guide.size,
        url: guide.url,
      };
      if (guide.coordinates) {
        this.marker = {
          coordinates: [guide.coordinates.long.toString(), guide.coordinates.lat.toString()],
        };
      }
    },
    setPlaceMarker(place) {
      if (place && !this.guide) {
        if (place.coordinates) {
          this.marker = {
            coordinates: [place.coordinates.long, place.coordinates.lat],
          };
        } else if (this.city) {
          this.marker = { coordinates: [this.city.coordinates.long, this.city.coordinates.lat] };
        }
      }
    },
  },
  computed: {
    mapboxgl() {
      return Mapbox;
    },
    guide() {
      if (
        !this.place ||
        !this.place.guides ||
        this.$props.guide_index == null ||
        this.$props.guide_index >= this.place.guides.length
      ) {
        return null;
      }
      return this.place.guides[this.$props.guide_index];
    },
    place() {
      if (!this.city || !this.$props.place_id) return null;
      return this.city.places[this.$props.place_id];
    },
    city() {
      if (!this.country || !this.$props.city_id) return null;
      return this.country.cities[this.$props.city_id];
    },
    country() {
      if (!this.guides || !this.$props.country_id) return null;
      return this.guides[this.$props.country_id];
    },
    guides() {
      return this.$store.state.jtbGuides;
    },
  },
  created() {
    if (this.guide) {
      this.setGuide(this.guide);
    }
  },
  watch: {
    guide(newVal, oldVal) {
      if (
        newVal &&
        (!oldVal ||
          newVal.title !== oldVal.title ||
          newVal.title_ja !== oldVal.title_ja ||
          newVal.coordinates !== oldVal.coordinates ||
          newVal.manuscript_ja !== oldVal.manuscript_ja)
      ) {
        this.setGuide(newVal);
      }
      if (!newVal && this.place) {
        this.setPlaceMarker(this.place);
      }
    },
    place(newVal) {
      this.setPlaceMarker(newVal);
    },
  },
};
</script>

<style >
.mgl-map-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.mgl-map-wrapper .mapboxgl-map {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
